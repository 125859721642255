/*
 * Variables
 */


//==================================================
//                     IMPORTS
//==================================================

//This only works when it's here
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300i,400,700,700i,900,900i')

//==================================================
//                   ENGINELAND
//==================================================

/*
 * Colors
 */

//Background colors
$default-polka-dot-color: #333
$default-polka-dot-size: 5px
$default-content-background-color: rgb(221,2,38)


$outer-stripe-color: rgba(255, 255, 255, 0.4)
$middle-stripe-color: rgba(255, 255, 255, 0.6)
$inner-stripe-color: rgba(255, 255, 255, 0.8)

/*
 * Home Page Header
 */

//$top-logo-height: 
$top-logo-height: 4rem
$top-logo-margin-bottom: 0.75rem
$slogan-font-size: 1.1rem

$nav-brand-padding-top: 1rem
$nav-brand-padding-bottom: 1rem

// nav.brand contains the car parts brand switcher
$nav-brand-width: 30rem
$nav-brand-outer-stripe-width-left: 6rem
$nav-brand-middle-stripe-width-left: 4rem
$nav-brand-inner-stripe-width-left: 2.4rem

$nav-brand-inner-stripe-width-right: 1.2rem
$nav-brand-middle-stripe-width-right: 2rem
$nav-brand-outer-stripe-width-right: 3rem

//stripe content needs to know padding
$nav-brand-padding-left: $nav-brand-outer-stripe-width-left + $nav-brand-middle-stripe-width-left + $nav-brand-inner-stripe-width-left
$nav-brand-padding-right: $nav-brand-outer-stripe-width-right + $nav-brand-middle-stripe-width-right + $nav-brand-inner-stripe-width-right
$nav-brand-content-width: $nav-brand-width - $nav-brand-padding-left - $nav-brand-padding-right - 4rem

$nav-brand-label-font-size: 1.3rem
$nav-brand-label-margin-bottom: 2rem

$brands-vertical-space: 6rem

/*
 * Slider
 */

$slider-mobile-height: 40vh

/*
 * logo area
 */

//height of actual image
$engineland-logo-height: 33.22px

//height of brand logos
$brand-logo-height: 6rem

//seperate logo and slogan from brand logos
$logo-area-margin-bottom: $brand-logo-height - 1rem

$brand-logo-margin-bottom: 3rem

/*
 * search bar
 */

//on the home page, this is how far the search bar stuff needs to move
//to the right
$brands-desktop-left: $nav-brand-outer-stripe-width-left + $nav-brand-middle-stripe-width-left

//since the search dropdown throws off flexbox align-items,
//this is the margin-top needed to manually align them
$top-bar-item-margin-top: 0.4rem

$top-bar-vertical-padding: 1.5rem

$top-bar-search-font-size: 1.8rem
$top-bar-search-border-width: 4px
$top-bar-form-padding: 0.8rem

$top-bar-phone-font-size: 2.6rem
$top-bar-email-font-size: 1.3rem
$top-bar-line-margin-top: 0.8rem
$top-bar-line-margin-bottom: 0.5rem
$top-bar-search-height: $top-bar-search-font-size + $top-bar-line-margin-top + $top-bar-line-margin-bottom + $top-bar-form-padding 

//$top-bar-height: ($top-bar-vertical-padding * 2) + $top-bar-phone-font-size + $top-bar-email-font-size
$top-bar-height: ($top-bar-vertical-padding * 2) + $top-bar-search-height

$top-logo-area-height: $nav-brand-padding-top + $top-logo-height + $top-logo-margin-bottom + $slogan-font-size

//so that the serach bar is moved to the right far enough on desktop
$nav-position-from-left: $nav-brand-width + $brands-desktop-left + $nav-brand-padding-right

//slider height
$slogan-bottom-margin: $brand-logo-height - 1rem
$nav-brand-height: $top-logo-area-height + $slogan-bottom-margin + $nav-brand-label-font-size + $nav-brand-label-margin-bottom + (($brand-logo-height + $brand-logo-margin-bottom) * 3) + $nav-brand-padding-bottom + 2rem + 1.8rem + 0.2rem + 1rem

$top-slider-height: $nav-brand-height - $top-bar-height

/*
 * Section Headers
 */

$section-header-font-size: 3.6rem
$section-header-padding-up-down: 0.75rem
$section-header-padding-left-right: 1rem

//subpages without headers need a padding placeholder
// 2rem and 1rem are the default bootstrap margins. I'm expecting a base font size of 10px
$section-header-height: $section-header-font-size + ($section-header-padding-up-down * 2) + 2rem + 1rem


$section-header-outer-stripe: 3rem
$section-header-middle-stripe: 2rem
$section-header-inner-stripe: 1.5rem
$section-header-underline-width: 3px

$section-header-padding-left: $section-header-outer-stripe + $section-header-middle-stripe + $section-header-inner-stripe

$section-padding-top: 3rem

/*
 * Content Elements
 */

$product-image-outline-width: 10px


/*
 * bottom-navigation
 */
$bottom-upper-stripe-width: 3.2rem
$bottom-middle-stripe-width: 1.6rem
$bottom-lower-stripe-width: 0.96rem

$bottom-nav-offset: $bottom-upper-stripe-width + $bottom-middle-stripe-width + $bottom-lower-stripe-width


//==================================================
//                   BOOTSTRAP
//==================================================

$bootstrap-sass-asset-helper: false


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000
$gray-darker:            lighten($gray-base, 13.5%)  // #222
$gray-dark:              lighten($gray-base, 20%)    // #333
$gray:                   lighten($gray-base, 33.5%)  // #555
$gray-light:             lighten($gray-base, 46.7%)  // #777
$gray-lighter:           lighten($gray-base, 93.5%)  // #eee

$brand-primary:         #ee0229
$brand-success:         #5cb85c 
$brand-info:            #5bc0de 
$brand-warning:         #f0ad4e 
$brand-danger:          #d9534f


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff
//** Global text color on `<body>`.
$text-color:            $gray-dark 

//** Global textual link color.
$link-color:            $brand-primary 
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%) 
//** Link hover decoration.
$link-hover-decoration: none


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  'Source Sans Pro', sans-serif
$font-family-serif:       Georgia, "Times New Roman", Times, serif 
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace 
$font-family-base:        $font-family-sans-serif 

$font-size-base:          14px 
$font-size-large:         ceil(($font-size-base * 1.25))  // ~18px
$font-size-small:         ceil(($font-size-base * 0.85))  // ~12px

$font-size-h1:            floor(($font-size-base * 2.6))  // ~36px
$font-size-h2:            floor(($font-size-base * 2.15))  // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7))  // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25))  // ~18px
$font-size-h5:            $font-size-base 
$font-size-h6:            ceil(($font-size-base * 0.85))  // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429  // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base))  // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit 
$headings-font-weight:    500 
$headings-line-height:    1.1 
$headings-color:          inherit 


//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.

// [converter] If $bootstrap-sass-asset-helper if used, provide path relative to the assets load path.
// [converter] This is because some asset helpers, such as Sprockets, do not work with file-relative paths.
$icon-font-path: if($bootstrap-sass-asset-helper, "bootstrap/", "../fonts/bootstrap/") 

//** File name for all font files.
$icon-font-name:          "glyphicons-halflings-regular" 
//** Element ID within SVG icon file.
$icon-font-svg-id:        "glyphicons_halflingsregular" 


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     6px 
$padding-base-horizontal:   12px 

$padding-large-vertical:    10px 
$padding-large-horizontal:  16px 

$padding-small-vertical:    5px 
$padding-small-horizontal:  10px 

$padding-xs-vertical:       1px 
$padding-xs-horizontal:     5px 

$line-height-large:         1.3333333  // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 

//$border-radius-base:        4px 
//$border-radius-large:       6px 
//$border-radius-small:       3px 
$border-radius-base:        0
$border-radius-large:       0
$border-radius-small:       0

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    #fff 
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $brand-primary 

//** Width of the `border` for generating carets that indicate dropdowns.
$caret-width-base:          4px 
//** Carets increase slightly in size for larger components.
$caret-width-large:         5px 


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px 
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  5px 

//** Default background color used for all tables.
$table-bg:                      transparent 
//** Background color used for `.table-striped`.
$table-bg-accent:               #f9f9f9 
//** Background color used for `.table-hover`.
$table-bg-hover:                #f5f5f5 
$table-bg-active:               $table-bg-hover 

//** Border color for table and cell borders.
$table-border-color:            #ddd 


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                700

$btn-default-color:              #333
$btn-default-bg:                 #fff
$btn-default-border:             #000

$btn-primary-color:              #fff
$btn-primary-bg:                 $brand-primary
$btn-primary-border:             darken($btn-primary-bg, 5%)

$btn-success-color:              #fff
$btn-success-bg:                 $brand-success
$btn-success-border:             darken($btn-success-bg, 5%)

$btn-info-color:                 #fff
$btn-info-bg:                    $brand-info
$btn-info-border:                darken($btn-info-bg, 5%)

$btn-warning-color:              #fff
$btn-warning-bg:                 $brand-warning
$btn-warning-border:             darken($btn-warning-bg, 5%)

$btn-danger-color:               #fff
$btn-danger-bg:                  $brand-danger
$btn-danger-border:              darken($btn-danger-bg, 5%)

$btn-link-disabled-color:        $gray-light

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base
$btn-border-radius-large:        $border-radius-large
$btn-border-radius-small:        $border-radius-small


//== Forms
//
//##

//** `<input>` background color
$input-bg:                       #fff 
//** `<input disabled>` background color
$input-bg-disabled:              $gray-lighter 

//** Text color for `<input>`s
$input-color:                    #000
//** `<input>` border color
$input-border:                   #000

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius:            $border-radius-base 
//** Large `.form-control` border radius
$input-border-radius-large:      $border-radius-large 
//** Small `.form-control` border radius
$input-border-radius-small:      $border-radius-small 

//** Border color for inputs on focus
$input-border-focus:             $brand-info

//** Placeholder text color
$input-color-placeholder:        #000

//** Default `.form-control` height
$input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2) 
//** Large `.form-control` height
$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) 
//** Small `.form-control` height
$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) 

//** `.form-group` margin
$form-group-margin-bottom:       15px 

$legend-color:                   $gray-dark 
$legend-border-color:            #e5e5e5 

//** Background color for textual input addons
$input-group-addon-bg:           $gray-lighter 
//** Border color for textual input addons
$input-group-addon-border-color: $input-border 

//** Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed 


//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg:                    #fff 
//** Dropdown menu `border-color`.
$dropdown-border:                rgba(0,0,0,.15) 
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border:       #ccc 
//** Divider color for between dropdown items.
$dropdown-divider-bg:            #e5e5e5 

//** Dropdown link text color.
$dropdown-link-color:            $gray-dark 
//** Hover color for dropdown links.
$dropdown-link-hover-color:      darken($gray-dark, 5%) 
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         #f5f5f5 

//** Active dropdown menu item text color.
$dropdown-link-active-color:     $component-active-color 
//** Active dropdown menu item background color.
$dropdown-link-active-bg:        $component-active-bg 

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color:   $gray-light 

//** Text color for headers within dropdown menus.
$dropdown-header-color:          $gray-light 

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color:           #000 


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            1000 
$zindex-dropdown:          1000 
$zindex-popover:           1060 
$zindex-tooltip:           1070 
$zindex-navbar-fixed:      1030 
$zindex-modal-background:  1040 
$zindex-modal:             1050 


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px 
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs 
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min 

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px 
$screen-sm-min:              $screen-sm 
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min 

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px 
$screen-md-min:              $screen-md 
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min 

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px 
$screen-lg-min:              $screen-lg 
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min 

// So media queries don't overlap when required, provide a maximum
$screen-xxs-max:             ($screen-xs-min - 1)
$screen-xs-max:              ($screen-sm-min - 1) 
$screen-sm-max:              ($screen-md-min - 1) 
$screen-md-max:              ($screen-lg-min - 1) 
//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px 
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min 
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) 


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) 
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet 

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) 
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop 

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) 
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop 


//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    50px 
$navbar-margin-bottom:             $line-height-computed 
$navbar-border-radius:             $border-radius-base 
$navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) 
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) 
$navbar-collapse-max-height:       340px 

$navbar-default-color:             #777 
$navbar-default-bg:                #f8f8f8 
$navbar-default-border:            darken($navbar-default-bg, 6.5%) 

// Navbar links
$navbar-default-link-color:                #777 
$navbar-default-link-hover-color:          #333 
$navbar-default-link-hover-bg:             transparent 
$navbar-default-link-active-color:         #555 
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) 
$navbar-default-link-disabled-color:       #ccc 
$navbar-default-link-disabled-bg:          transparent 

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color 
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%) 
$navbar-default-brand-hover-bg:            transparent 

// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd 
$navbar-default-toggle-icon-bar-bg:        #888 
$navbar-default-toggle-border-color:       #ddd 


//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%) 
$navbar-inverse-bg:                         #222 
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%) 

// Inverted navbar links
$navbar-inverse-link-color:                 lighten($gray-light, 15%) 
$navbar-inverse-link-hover-color:           #fff 
$navbar-inverse-link-hover-bg:              transparent 
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color 
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%) 
$navbar-inverse-link-disabled-color:        #444 
$navbar-inverse-link-disabled-bg:           transparent 

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color 
$navbar-inverse-brand-hover-color:          #fff 
$navbar-inverse-brand-hover-bg:             transparent 

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            #333 
$navbar-inverse-toggle-icon-bar-bg:         #fff 
$navbar-inverse-toggle-border-color:        #333 


//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding:                          10px 15px 
$nav-link-hover-bg:                         $gray-lighter 

$nav-disabled-link-color:                   $gray-light 
$nav-disabled-link-hover-color:             $gray-light 

//== Tabs
$nav-tabs-border-color:                     #ddd 

$nav-tabs-link-hover-border-color:          $gray-lighter 

$nav-tabs-active-link-hover-bg:             $body-bg 
$nav-tabs-active-link-hover-color:          $gray 
$nav-tabs-active-link-hover-border-color:   #ddd 

$nav-tabs-justified-link-border-color:            #ddd 
$nav-tabs-justified-active-link-border-color:     $body-bg 

//== Pills
$nav-pills-border-radius:                   $border-radius-base 
$nav-pills-active-link-hover-bg:            $component-active-bg 
$nav-pills-active-link-hover-color:         $component-active-color 


//== Pagination
//
//##

$pagination-color:                     $link-color 
$pagination-bg:                        #fff 
$pagination-border:                    #ddd 

$pagination-hover-color:               $link-hover-color 
$pagination-hover-bg:                  $gray-lighter 
$pagination-hover-border:              #ddd 

$pagination-active-color:              #fff 
$pagination-active-bg:                 $brand-primary 
$pagination-active-border:             $brand-primary 

$pagination-disabled-color:            $gray-light 
$pagination-disabled-bg:               #fff 
$pagination-disabled-border:           #ddd 


//== Pager
//
//##

$pager-bg:                             $pagination-bg 
$pager-border:                         $pagination-border 
$pager-border-radius:                  15px 

$pager-hover-bg:                       $pagination-hover-bg 

$pager-active-bg:                      $pagination-active-bg 
$pager-active-color:                   $pagination-active-color 

$pager-disabled-color:                 $pagination-disabled-color 


//== Jumbotron
//
//##

$jumbotron-padding:              30px 
$jumbotron-color:                inherit 
$jumbotron-bg:                   $gray-lighter 
$jumbotron-heading-color:        inherit 
$jumbotron-font-size:            ceil(($font-size-base * 1.5)) 
$jumbotron-heading-font-size:    ceil(($font-size-base * 4.5)) 


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d 
$state-success-bg:               #dff0d8 
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) 

$state-info-text:                #31708f 
$state-info-bg:                  #d9edf7 
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) 

$state-warning-text:             #8a6d3b 
$state-warning-bg:               #fcf8e3 
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) 

$state-danger-text:              #a94442 
$state-danger-bg:                #f2dede 
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) 


//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width:           200px 
//** Tooltip text color
$tooltip-color:               #fff 
//** Tooltip background color
$tooltip-bg:                  #000 
$tooltip-opacity:             .9 

//** Tooltip arrow width
$tooltip-arrow-width:         5px 
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg 


//== Popovers
//
//##

//** Popover body background color
$popover-bg:                          #fff 
//** Popover maximum width
$popover-max-width:                   276px 
//** Popover border color
$popover-border-color:                rgba(0,0,0,.2) 
//** Popover fallback border color
$popover-fallback-border-color:       #ccc 

//** Popover title background color
$popover-title-bg:                    darken($popover-bg, 3%) 

//** Popover arrow width
$popover-arrow-width:                 10px 
//** Popover arrow color
$popover-arrow-color:                 $popover-bg 

//** Popover outer arrow width
$popover-arrow-outer-width:           ($popover-arrow-width + 1) 
//** Popover outer arrow color
$popover-arrow-outer-color:           fade_in($popover-border-color, 0.05) 
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%) 


//== Labels
//
//##

//** Default label background color
$label-default-bg:            $gray-light 
//** Primary label background color
$label-primary-bg:            $brand-primary 
//** Success label background color
$label-success-bg:            $brand-success 
//** Info label background color
$label-info-bg:               $brand-info 
//** Warning label background color
$label-warning-bg:            $brand-warning 
//** Danger label background color
$label-danger-bg:             $brand-danger 

//** Default label text color
$label-color:                 #fff 
//** Default text color of a linked label
$label-link-hover-color:      #fff 


//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding:         15px 

//** Padding applied to the modal title
$modal-title-padding:         15px 
//** Modal title line-height
$modal-title-line-height:     $line-height-base 

//** Background color of modal content area
$modal-content-bg:                             #fff 
//** Modal content border color
$modal-content-border-color:                   rgba(0,0,0,.2) 
//** Modal content border color **for IE8**
$modal-content-fallback-border-color:          #999 

//** Modal backdrop background color
$modal-backdrop-bg:           #000 
//** Modal backdrop opacity
$modal-backdrop-opacity:      .5 
//** Modal header border color
$modal-header-border-color:   #e5e5e5 
//** Modal footer border color
$modal-footer-border-color:   $modal-header-border-color 

$modal-lg:                    900px 
$modal-md:                    600px 
$modal-sm:                    300px 


//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding:               15px 
$alert-border-radius:         $border-radius-base 
$alert-link-font-weight:      bold 

$alert-success-bg:            $state-success-bg 
$alert-success-text:          $state-success-text 
$alert-success-border:        $state-success-border 

$alert-info-bg:               $state-info-bg 
$alert-info-text:             $state-info-text 
$alert-info-border:           $state-info-border 

$alert-warning-bg:            $state-warning-bg 
$alert-warning-text:          $state-warning-text 
$alert-warning-border:        $state-warning-border 

$alert-danger-bg:             $state-danger-bg 
$alert-danger-text:           $state-danger-text 
$alert-danger-border:         $state-danger-border 


//== Progress bars
//
//##

//** Background color of the whole progress component
$progress-bg:                 #f5f5f5 
//** Progress bar text color
$progress-bar-color:          #fff 
//** Variable for setting rounded corners on progress bar.
$progress-border-radius:      $border-radius-base 

//** Default progress bar color
$progress-bar-bg:             $brand-primary 
//** Success progress bar color
$progress-bar-success-bg:     $brand-success 
//** Warning progress bar color
$progress-bar-warning-bg:     $brand-warning 
//** Danger progress bar color
$progress-bar-danger-bg:      $brand-danger 
//** Info progress bar color
$progress-bar-info-bg:        $brand-info 


//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg:                 #fff 
//** `.list-group-item` border color
$list-group-border:             #ddd 
//** List group border radius
$list-group-border-radius:      $border-radius-base 

//** Background color of single list items on hover
$list-group-hover-bg:           #f5f5f5 
//** Text color of active list items
$list-group-active-color:       $component-active-color 
//** Background color of active list items
$list-group-active-bg:          $component-active-bg 
//** Border color of active list elements
$list-group-active-border:      $list-group-active-bg 
//** Text color for content within active list items
$list-group-active-text-color:  lighten($list-group-active-bg, 40%) 

//** Text color of disabled list items
$list-group-disabled-color:      $gray-light 
//** Background color of disabled list items
$list-group-disabled-bg:         $gray-lighter 
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color 

$list-group-link-color:         #555 
$list-group-link-hover-color:   $list-group-link-color 
$list-group-link-heading-color: #333 


//== Panels
//
//##

$panel-bg:                    #fff 
$panel-body-padding:          15px 
$panel-heading-padding:       10px 15px 
$panel-footer-padding:        $panel-heading-padding 
$panel-border-radius:         $border-radius-base 

//** Border color for elements within panels
$panel-inner-border:          #ddd 
$panel-footer-bg:             #f5f5f5 

$panel-default-text:          $gray-dark 
$panel-default-border:        #ddd 
$panel-default-heading-bg:    #f5f5f5 

$panel-primary-text:          #fff 
$panel-primary-border:        $brand-primary 
$panel-primary-heading-bg:    $brand-primary 

$panel-success-text:          $state-success-text 
$panel-success-border:        $state-success-border 
$panel-success-heading-bg:    $state-success-bg 

$panel-info-text:             $state-info-text 
$panel-info-border:           $state-info-border 
$panel-info-heading-bg:       $state-info-bg 

$panel-warning-text:          $state-warning-text 
$panel-warning-border:        $state-warning-border 
$panel-warning-heading-bg:    $state-warning-bg 

$panel-danger-text:           $state-danger-text 
$panel-danger-border:         $state-danger-border 
$panel-danger-heading-bg:     $state-danger-bg 


//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding:           4px 
//** Thumbnail background color
$thumbnail-bg:                $body-bg 
//** Thumbnail border color
$thumbnail-border:            #ddd 
//** Thumbnail border radius
$thumbnail-border-radius:     $border-radius-base 

//** Custom text color for thumbnail captions
$thumbnail-caption-color:     $text-color 
//** Padding around the thumbnail caption
$thumbnail-caption-padding:   9px 


//== Wells
//
//##

$well-bg:                     #f5f5f5 
$well-border:                 darken($well-bg, 7%) 


//== Badges
//
//##

$badge-color:                 #fff 
//** Linked badge text color on hover
$badge-link-hover-color:      #fff 
$badge-bg:                    $gray-light 

//** Badge text color in active nav link
$badge-active-color:          $link-color 
//** Badge background color in active nav link
$badge-active-bg:             #fff 

$badge-font-weight:           bold 
$badge-line-height:           1 
$badge-border-radius:         10px 


//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical:   8px 
$breadcrumb-padding-horizontal: 15px 
//** Breadcrumb background color
$breadcrumb-bg:                 #f5f5f5 
//** Breadcrumb text color
$breadcrumb-color:              #ccc 
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:       $gray-light 
//** Textual separator for between breadcrumb elements
$breadcrumb-separator:          "/" 


//== Carousel
//
//##

$carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6) 

$carousel-control-color:                      #fff 
$carousel-control-width:                      15% 
$carousel-control-opacity:                    .5 
$carousel-control-font-size:                  20px 

$carousel-indicator-active-bg:                #fff 
$carousel-indicator-border-color:             #fff 

$carousel-caption-color:                      #fff 


//== Close
//
//##

$close-font-weight:           bold 
$close-color:                 #000 
$close-text-shadow:           0 1px 0 #fff 


//== Code
//
//##

$code-color:                  #c7254e 
$code-bg:                     #f9f2f4 

$kbd-color:                   #fff 
$kbd-bg:                      #333 

$pre-bg:                      #f5f5f5 
$pre-color:                   $gray-dark 
$pre-border-color:            #ccc 
$pre-scrollable-max-height:   340px 


//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px 
//** Text muted color
$text-muted:                  $gray-light 
//** Abbreviations and acronyms border color
$abbr-border-color:           $gray-light 
//** Headings small color
$headings-small-color:        $gray-light 
//** Blockquote small color
$blockquote-small-color:      $gray-light 
//** Blockquote font size
$blockquote-font-size:        ($font-size-base * 1.25) 
//** Blockquote border color
$blockquote-border-color:     $gray-lighter 
//** Page header border color
$page-header-border-color:    $gray-lighter 
//** Width of horizontal description list titles
$dl-horizontal-offset:        $component-offset-horizontal 
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint:    $grid-float-breakpoint 
//** Horizontal line color.
$hr-border:                   $gray-lighter 


