/*
 * Mixins
 */


//==================================================
//                     MIXINS
//==================================================

//slide down animation mixins
=slide-down-initial-state
    max-height: 0
    overflow: hidden
    padding: 0
    border: 0
    opacity: 0

//don't forget to add
//margin, border, and padding
=slide-down-open
    opacity: 1
    max-height: 100vh

//hover animations
//add this to a container with links
=hover-container-links($opacity)
    a:link, a:visited
        transition: opacity 0.5s
        &::before
            transition: opacity 0.5s

    &:hover
        a:link, a:visited
            opacity: $opacity
            transition: opacity 0.2s

            //icon
            &::before
                opacity: $opacity
                transition: opacity 0.2s

        a:hover, a:active
            text-decoration: none
            opacity: 1

            &::before
                opacity: 1

        a::before:hover, a::before:active
            opacity: 1

    //if link is of the current page,
    //highlight it
    a.active:link, a.active:visited
        opacity: 1

        &::before
            opacity: 1

//links will be faded by default
//add to a container with links
=hover-links($opacity)

    //default
    a:link, a:visited
        opacity: $opacity
        transition: opacity 0.5s
        &::before
            opacity: $opacity
            transition: opacity 0.5s

    //hover
    a:hover, a:active
        text-decoration: none
        opacity: 1

        &::before
            opacity: 1

    //if link is of the current page,
    //highlight it
    a.active:link, a.active:visited
        opacity: 1

        &::before
            opacity: 1

//gets the margin between a bootstrap
//container and the edge of the
//screen, and assigns that amount to
//the selected property
//
//i.e. keeps shit together
=outside-container-margin($property)
    @media screen and (min-width: $screen-sm-min)
        #{$property}: calc((100% - #{$container-sm}) / 2)
    @media screen and (min-width: $screen-md-min)
        #{$property}: calc((100% - #{$container-md}) / 2)
    @media screen and (min-width: $screen-lg-min)
        #{$property}: calc((100% - #{$container-lg}) / 2)


//on hover, a line appears from left to right
=line-left-to-right($line-color)
    position: relative
    display: inline-block

    &::after
        display: block
        content: ''
        position: absolute
        bottom: 0
        left: 0
        background-color: $line-color
        width: 0
        height: 2px
        transition: all 0.2s ease-out

    &:hover::after
        height: 2px
        width: 100%

=font-awesome($character:'')
    font-family: FontAwesome
    font-style: normal
    font-weight: normal
    text-decoration: inherit
    display: inline-block
    content: $character

//used for nav in featured items
$featured-items-nav-line-width: 2px
=divider-line-to-top
    &::after
        content: ''
        position: absolute
        bottom: 0
        right: -$featured-items-nav-line-width
        width: $featured-items-nav-line-width
        background-image: linear-gradient(transparent, #fff)

        //rejig this depending on number of icons
        height: 150%
        @media screen and (min-width: #{$screen-md-min})
            height: 175%

//used for categories in product search in mobile
=horizontal-divider-line
    &::before
        content: ''
        position: absolute
        bottom: 0
        width: 100%
        background-image: linear-gradient(to left, transparent, $brand-primary, transparent)
        height: 2px


// This inserts a line to the left and a line to the right of the content
// The width determines how far these linese extend. So far, only a width
// of 100% is used.
=line-filler($width, $color)
    display: flex
    align-items: center
    justify-content: center

    &::before, &::after
        height: 3px
        flex-grow: if($width == 100%, 2, 0)
        content: ''
        background-color: $color

        //these need a width, any width, in order to be displayed
        width: 1rem

    &::before
        margin-right: 0.5rem

    &::after
        margin-left: 0.5rem

=move-right($units)
    margin-left: $units
    margin-right: -$units

=move-left($units)
    margin-left: -$units
    margin-right: $units


=dark-background-text
    color: #fff
    a:link, a:visited, a:hover, a:active
        color: #fff

=light-background-text
    color: #333
    a:link, a:visited, a:hover, a:active
        color: #333

//adds a red polka-dot background
=polka-dot-background
    +dark-background-text
    background-image: url('/assets/theme/img/red-bg.jpg')
    background-size: 5px 5px
    background-repeat: repeat

=polka-dot-background-speedometer
    +polka-dot-background

    //position the speeeeeeeeeeeeeeeeed(ometer)
    &::after
        content: ''
        display: block
        overflow: hidden
        position: absolute
        bottom: 0
        right: 0
        width: 65rem
        height: 39rem
        background-image: url('/assets/theme/img/speedometer.png')
        background-size: contain
        background-repeat: no-repeat
        filter: opacity(10%)
        z-index: 0

        //pixel screwing for screens > $screen-md-min
        background-position: right 0 bottom -6rem

        max-width: 100%
        @media screen and (max-width: #{$screen-md-min})
            max-height: 45vh
            background-position: right 0 bottom -4rem

//adds a background with css polka dots
=css3-polka-dot-background($polka-dot-color, $polka-dot-size, $background-color)
    height: 100%
    background-image: radial-gradient($polka-dot-color 15%, transparent 16%), radial-gradient($polka-dot-color 15%, transparent 16%), linear-gradient(to bottom, $background-color, $background-color)
    background-position: 0 0, $polka-dot-size $polka-dot-size, bottom
    background-size: $polka-dot-size * 2 $polka-dot-size * 2, $polka-dot-size * 2 $polka-dot-size * 2, 100%, 100%
    background-size: $polka-dot-size * 2 $polka-dot-size * 2, $polka-dot-size * 2 $polka-dot-size * 2, cover, cover
    background-repeat: repeat, repeat, no-repeat

// adds speedometer at the bottom right
=css3-polka-dot-background-speedometer($polka-dot-color, $polka-dot-size, $background-color)
    height: 100%
    background-image: radial-gradient($polka-dot-color 15%, transparent 16%), radial-gradient($polka-dot-color 15%, transparent 16%), url('/assets/theme/img/speedometer.png'), linear-gradient(to bottom, $background-color, $background-color)
    background-position: 0 0, $polka-dot-size $polka-dot-size, bottom right
    background-size: $polka-dot-size * 2 $polka-dot-size * 2, $polka-dot-size * 2 $polka-dot-size * 2, 25%
    background-repeat: repeat, repeat, no-repeat

=background-tire-tracks
    position: relative
    &::before
        position: absolute
        top: 0
        left: 3rem
        content: ''
        width: 70rem
        max-width: calc(100% - 3rem)
        height: 37rem
        filter: opacity(10%)
        z-index: 0

        background-image: url('/assets/theme/img/tire-tracks.png')
        background-position: 0 -3.5rem
        background-repeat: no-repeat
        background-size: 100%


// Animation ($type = bounce, etc.)
=animate($type, $time)
    -webkit-animation: 	$type 150ms ease-in // Chrome, Safari, Opera
    -o-animation: 			$type 150ms ease-in
    animation: 					$type 150ms ease-in

// Chrome, Safari, Opera
@-webkit-keyframes bounce
    0%
        transform: scale(0)
    90%
        transform: scale(1.3)
    100%
        transform: scale(1)

// Standard syntax
@keyframes bounce
    0%
        transform: scale(0)
    90%
        transform: scale(1.3)
    100%
        transform: scale(1)


//==================================================
//                    ICONS
//==================================================

/* For category icons:
 * background-image must be set in _layout.twig
 */

=icon
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    content: ''

//section icons
=section-icon
    &::before
        +icon
        content: ''
        display: block
        z-index: 0
        position: absolute
        left: -5rem
        top: 0
        width: 30rem
        height: 30rem
        opacity: 0.05

=logo-red
    +icon
    background-image: url('/assets/theme/img/engineland-logo.png')

=logo-white
    +icon
    background-image: url('/assets/theme/img/engineland-logo-white.png')



